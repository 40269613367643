import Vue from 'vue';
import VueRouter from 'vue-router';
import Layout from '@/layout/index.vue';
import NotFound from '@/views/404/index.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/',
    children: [
      {
        path: '/',
        name: 'Home',
        meta: { hideToTop: true },
        component: () => import('../views/home/index.vue'),
      },
      {
        path: 'partnership',
        name: 'Partnership',
        meta: { hideToTop: true },
        component: () => import('../views/partnership/index.vue'),
      },
      {
        path: 'about-us',
        name: 'AboutUs',
        meta: { hideToTop: true },
        component: () => import('../views/about/index.vue'),
      },
      {
        path: 'contact',
        name: 'Contact',
        meta: { hideToTop: true },
        component: () => import('../views/contact/index.vue'),
      },
      {
        path: '/product-solutions/automotive/adas',
        name: 'ADAS',
        component: () => import('../views/adas/index.vue'),
      },
      {
        path: '/product-solutions/automotive/in-cabin',
        name: 'InCabin',
        component: () => import('../views/in-cabin/index.vue'),
      },
      {
        path: '/product-solutions/automotive/apa',
        name: 'APA',
        component: () => import('../views/apa/index.vue'),
      },
      {
        path: '/product-solutions/automotive/commercial-vehicles',
        name: 'CommercialVehicles',
        component: () => import('../views/commercial-vehicles/index.vue'),
      },
      {
        path: '/product-solutions/smartphone/turbo-fusion',
        name: 'TurboFusion',
        component: () => import('../views/turbo-fusion/index.vue'),
      },
      {
        path: '/product-solutions/smartphone/aigc',
        name: 'AigcSolutions',
        component: () => import('../views/aigc-solutions/index.vue'),
      },
      {
        path: 'privacy-policy',
        name: 'privacyPolicy',
        component: () => import('../views/other/privacyPolicy.vue'),
      },
      {
        path: 'terms-of-use',
        name: 'termsOfUse',
        component: () => import('../views/other/termsOfUse.vue'),
      },
    ],
  },
  {
    path: '*',
    component: Layout,
    redirect: '404',
    children: [
      {
        path: '404',
        name: '404',
        component: NotFound,
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // scrollBehavior: () => ({ y: 0 }),
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth', // 平滑滚动
      };
    } else {
      return { y: 0 }; // 默认滚动到顶部
    }
  },
});

export default router;
